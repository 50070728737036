/* eslint-disable */
/* eslint-disable camelcase */
const generate_pdf_content = (form_name, fields, model, status_list, logoBase64, checklilstName = '', projectdata = '', allTransData, dataGridShow, reportHeader = '') => {
  if(reportHeader){
    form_name = reportHeader
  }
  let styleLink = `<link href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" rel="stylesheet" />
    <style>
      tbody tr, .panel-body { page-break-inside:avoid; font-size: 18px}
      tbody tr td { text-align: justify;word-break: break-word;}
      .switch {position: relative;display: inline-block;width: 50px;height: 25px;}
      .switch input {opacity: 0;width: 0;height: 0;}
      .slider {position: absolute;cursor: pointer;top: 0;left: 0;right: 0;bottom: 0;background-color: #ccc;-webkit-transition: .4s;transition: .4s;}
      .slider:before {position: absolute;content: "";height: 19px;width: 19px;left: 2px;bottom: 3px;background-color: white;-webkit-transition: .4s;transition: .4s;}
      input:checked + .slider {background-color: #1976d2;}
      input:focus + .slider {box-shadow: 0 0 1px #1976d2;}
      input:checked + .slider:before {-webkit-transform: translateX(26px);-ms-transform: translateX(26px);transform: translateX(26px);}
      .slider.round {border-radius: 34px;}
      .slider.round:before {border-radius: 50%;}
      .textWrap { word-break: break-all }
      .custom-margin-left{margin-left: 0px !important; margin-right: 10px !important;}
      .div-padding { padding-left: 0.35rem; padding-right: 0.35rem; }
      .custom-row-margin { margin: 0 0 1rem; }
      .row-margin-zero { margin: 0; }
      .signature_13 {
        border: 1px solid rgba(0, 0, 0, 0.8);
        border-radius: 3px;
        padding: 0.5rem;
      }
      table, tr, td, th, tbody, thead, tfoot {
        page-break-inside: avoid !important;
      }
      .wordbreak {
        white-space: pre-wrap;
        /* CSS3 */
        white-space: -moz-pre-wrap;
        /* Firefox */
        white-space: -pre-wrap;
        /* Opera <7 */
        white-space: -o-pre-wrap;
        /* Opera 7 */
        word-wrap: break-word;
        /* IE */
        -ms-word-break: break-all;
        word-break: break-all;
        /* Non standard for WebKit */
        word-break: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto
      }
      .badge {
        border-radius: 3px;
        width: fit-content !important;
      }
      table.table-padding-adjusted {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }
      table.table-padding-adjusted > thead > tr > th,
      table.table-padding-adjusted > tbody > tr > td {
          padding: 0.25rem !important;
      }
      .custom-margin { margin-bottom: 0.15rem; }
      .space-left-right {margin-right: 1.25rem;}
      .sign-date{ margin: 0 0.5rem;}
      .md-radio {
        position: relative;
        /* handling click events */
        /* when radio is checked */ }
        .md-radio input[type=radio] {
          visibility: hidden;
          position: absolute; }
        .md-radio label {
          cursor: pointer;
          padding-left: 30px; }
        .md-radio label > span {
          display: block;
          position: absolute;
          left: 0;
          -webkit-transition-duration: 0.3s;
          -moz-transition-duration: 0.3s;
          transition-duration: 0.3s; }
        .md-radio label > span.inc {
          background: #fff;
          left: -20px;
          top: -20px;
          height: 60px;
          width: 60px;
          opacity: 0;
          border-radius: 50% !important;
          -moz-border-radius: 50% !important;
          -webkit-border-radius: 50% !important; }
        .md-radio label > .box {
          top: 0px;
          border: 2px solid #666;
          height: 20px;
          width: 20px;
          border-radius: 50% !important;
          -moz-border-radius: 50% !important;
          -webkit-border-radius: 50% !important;
          z-index: 5; }
        .md-radio label > .check {
          top: 5px;
          left: 5px;
          width: 10px;
          height: 10px;
          background: #36c6d3;
          opacity: 0;
          z-index: 6;
          border-radius: 50% !important;
          -moz-border-radius: 50% !important;
          -webkit-border-radius: 50% !important;
          -webkit-transform: scale(0);
          -moz-transform: scale(0);
          transform: scale(0); }
        .md-radio label > span.inc {
          -webkit-animation: growCircleRadio 0.3s ease;
          -moz-animation: growCircleRadio 0.3s ease;
          animation: growCircleRadio 0.3s ease; }
        .md-radio input[type=radio]:checked ~ label > .check {
          opacity: 1;
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          transform: scale(1); }
        .md-radio input[type=radio]:disabled ~ label,
        .md-radio input[type=radio][disabled] ~ label {
          cursor: not-allowed;
          opacity: 0.7 ;
          filter: alpha(opacity=70) ; }
        .md-radio input[type=radio]:disabled ~ label > .box,
        .md-radio input[type=radio][disabled] ~ label > .box {
          cursor: not-allowed;
          opacity: 0.7 ;
          filter: alpha(opacity=70) ; }
        .md-radio input[type=radio]:disabled:checked ~ label > .check,
        .md-radio input[type=radio][disabled]:checked ~ label > .check {
          cursor: not-allowed;
          opacity: 0.7 ;
          filter: alpha(opacity=70) ; }
      
      .has-error .md-radio label,
      .has-error.md-radio label {
        color: #e73d4a; }
      
      .has-error .md-radio label > .box,
      .has-error.md-radio label > .box {
        border-color: #e73d4a; }
      
      .has-error .md-radio label > .check,
      .has-error.md-radio label > .check {
        background: #e73d4a; }
      
      .has-success .md-radio label,
      .has-success.md-radio label {
        color: #27a4b0; }
      
      .has-success .md-radio label > .box,
      .has-success.md-radio label > .box {
        border-color: #27a4b0; }
      
      .has-success .md-radio label > .check,
      .has-success.md-radio label > .check {
        background: #27a4b0; }
      
      .has-warning .md-radio label,
      .has-warning.md-radio label {
        color: #c29d0b; }
      
      .has-warning .md-radio label > .box,
      .has-warning.md-radio label > .box {
        border-color: #c29d0b; }
      
      .has-warning .md-radio label > .check,
      .has-warning.md-radio label > .check {
        background: #c29d0b; }
      
      .has-info .md-radio label,
      .has-info.md-radio label {
        color: #327ad5; }
      
      .has-info .md-radio label > .box,
      .has-info.md-radio label > .box {
        border-color: #327ad5; }
      
      .has-info .md-radio label > .check,
      .has-info.md-radio label > .check {
        background: #327ad5; }
      
      .form-md-radios {
        padding-top: 5px; }
        .form-md-radios > label {
          font-size: 14px;
          color: #888888;
          opacity: 1 ;
          filter: alpha(opacity=100) ; }
        .form-md-radios.has-error label {
          color: #ed6b75; }
        .form-md-radios.has-info label {
          color: #659be0; }
        .form-md-radios.has-success label {
          color: #36c6d3; }
        .form-md-radios.has-warning label {
          color: #F1C40F; }
      
      .md-radio-list {
        margin: 5px 0 5px 0; }
        .form-horizontal .md-radio-list {
          margin-top: 5px; }
        .md-radio-list .md-radio {
          display: block;
          margin-bottom: 10px; }
      
      .md-radio-inline {
        margin: 5px 0 5px 0; }
        .form-horizontal .md-radio-inline {
          margin-top: 7px; }
        .md-radio-inline .md-radio {
          display: inline-block;
          margin-right: 20px; }
          .md-radio-inline .md-radio:last-child {
            margin-right: 0; }
      
      /* bubble animation */
      @-webkit-keyframes growCircleRadio {
        0%, 100% {
          -webkit-transform: scale(0);
          opacity: 1; }
        70% {
          background: #eee;
          -webkit-transform: scale(1.25); } }
      
      @-moz-keyframes growCircleRadio {
        0%, 100% {
          -moz-transform: scale(0);
          opacity: 1; }
        70% {
          background: #eee;
          -moz-transform: scale(1.25); } }
      
      @keyframes growCircleRadio {
        0%, 100% {
          transform: scale(0);
          opacity: 1; }
        70% {
          background: #eee;
          transform: scale(1.25); } }
      
      .mt-radio,
      .mt-checkbox {
        display: inline-block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 15px;
        cursor: pointer;
        font-size: 14px;
        webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s; }
        .mt-radio.mt-radio-disabled, .mt-radio.mt-checkbox-disabled,
        .mt-checkbox.mt-radio-disabled,
        .mt-checkbox.mt-checkbox-disabled {
          opacity: 0.8 ;
          filter: alpha(opacity=80) ;
          cursor: not-allowed; }
        .mt-radio > input,
        .mt-checkbox > input {
          position: absolute;
          z-index: -1;
          opacity: 0 ;
          filter: alpha(opacity=0) ; }
        .mt-radio > span,
        .mt-checkbox > span {
          border: 1px solid transparent;
          position: absolute;
          top: 1px;
          left: 0;
          height: 18px;
          width: 18px;
          background: #E6E6E6; }
          .mt-radio > span:after,
          .mt-checkbox > span:after {
            content: '';
            position: absolute;
            display: none; }
        .mt-radio:hover > input:not([disabled]) ~ span,
        .mt-radio > input:focus ~ span,
        .mt-checkbox:hover > input:not([disabled]) ~ span,
        .mt-checkbox > input:focus ~ span {
          background: #d9d9d9;
          webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -ms-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s; }
        .mt-radio > input:checked ~ span,
        .mt-checkbox > input:checked ~ span {
          webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -ms-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;
          background: #d9d9d9; }
          .mt-radio > input:checked ~ span:after,
          .mt-checkbox > input:checked ~ span:after {
            display: block; }
        .mt-radio:hover > input:not([disabled]):checked ~ span,
        .mt-radio > input:checked ~ span,
        .mt-checkbox:hover > input:not([disabled]):checked ~ span,
        .mt-checkbox > input:checked ~ span {
          webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -ms-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;
          background: #d9d9d9; }
        .mt-radio > input:disabled ~ span,
        .mt-checkbox > input:disabled ~ span {
          opacity: 0.6 ;
          filter: alpha(opacity=60) ;
          pointer-events: none; }
        .mt-radio.mt-radio-outline > span, .mt-radio.mt-checkbox-outline > span,
        .mt-checkbox.mt-radio-outline > span,
        .mt-checkbox.mt-checkbox-outline > span {
          border: 1px solid #d9d9d9;
          background: none; }
        .mt-radio.mt-radio-outline:hover > input:not([disabled]) ~ span,
        .mt-radio.mt-radio-outline > input:focus ~ span, .mt-radio.mt-checkbox-outline:hover > input:not([disabled]) ~ span,
        .mt-radio.mt-checkbox-outline > input:focus ~ span,
        .mt-checkbox.mt-radio-outline:hover > input:not([disabled]) ~ span,
        .mt-checkbox.mt-radio-outline > input:focus ~ span,
        .mt-checkbox.mt-checkbox-outline:hover > input:not([disabled]) ~ span,
        .mt-checkbox.mt-checkbox-outline > input:focus ~ span {
          border: 1px solid #d9d9d9;
          background: none; }
        .mt-radio.mt-radio-outline > input:checked ~ span, .mt-radio.mt-checkbox-outline > input:checked ~ span,
        .mt-checkbox.mt-radio-outline > input:checked ~ span,
        .mt-checkbox.mt-checkbox-outline > input:checked ~ span {
          border: 1px solid #d9d9d9;
          background: none; }
        .mt-radio.mt-radio-outline:hover > input:not([disabled]):checked ~ span,
        .mt-radio.mt-radio-outline > input:checked ~ span, .mt-radio.mt-checkbox-outline:hover > input:not([disabled]):checked ~ span,
        .mt-radio.mt-checkbox-outline > input:checked ~ span,
        .mt-checkbox.mt-radio-outline:hover > input:not([disabled]):checked ~ span,
        .mt-checkbox.mt-radio-outline > input:checked ~ span,
        .mt-checkbox.mt-checkbox-outline:hover > input:not([disabled]):checked ~ span,
        .mt-checkbox.mt-checkbox-outline > input:checked ~ span {
          border: 1px solid #d9d9d9;
          background: none; }
      
      .mt-radio > span {
        -webkit-border-radius: 50%!important;
        -moz-border-radius: 50%!important;
        -ms-border-radius: 50%!important;
        -o-border-radius: 50%!important;
        border-radius: 50%!important; }
        .mt-radio > span:after {
          left: 5px;
          top: 5px;
          height: 6px;
          width: 6px;
          border-radius: 100% !important;
          background: #888888; }
      
      .mt-radio > input:disabled ~ span:after {
        background: #888888; }
      
      th > .mt-radio.mt-radio-single,
      td > .mt-radio.mt-radio-single {
        right: -5px; }
      
      .mt-checkbox > span:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid #888888;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg); }
      
      .mt-checkbox > input:disabled ~ span:after {
        border-color: #888888; }
      
      .form-inline .mt-checkbox {
        margin-left: 15px;
        margin-right: 15px; }
      
      th > .mt-checkbox.mt-checkbox-single,
      td > .mt-checkbox.mt-checkbox-single {
        right: -5px; }
      
      .mt-checkbox-list,
      .mt-radio-list {
        padding: 10px 0; }
        .form-horizontal .form-group .mt-checkbox-list, .form-horizontal .form-group
        .mt-radio-list {
          padding-top: 0; }
        .mt-checkbox-list .mt-checkbox,
        .mt-checkbox-list .mt-radio,
        .mt-radio-list .mt-checkbox,
        .mt-radio-list .mt-radio {
          display: block; }
      
      .mt-checkbox-inline,
      .mt-radio-inline {
        padding: 10px 0; }
        .form-horizontal .form-group .mt-checkbox-inline, .form-horizontal .form-group
        .mt-radio-inline {
          padding-top: 8px; }
        .mt-checkbox-inline .mt-checkbox,
        .mt-checkbox-inline .mt-radio,
        .mt-radio-inline .mt-checkbox,
        .mt-radio-inline .mt-radio {
          display: inline-block;
          margin-right: 15px; }
          .mt-checkbox-inline .mt-checkbox:last-child,
          .mt-checkbox-inline .mt-radio:last-child,
          .mt-radio-inline .mt-checkbox:last-child,
          .mt-radio-inline .mt-radio:last-child {
            margin-right: 0; }
            .md-checkbox {
        position: relative;
        /* handling click events */
        /* when checkbox is checked */ }
        .md-checkbox.md-checkbox-inline {
          display: inline-block; }
        .form-inline .md-checkbox.md-checkbox-inline {
          margin-right: 20px;
          top: 3px; }
        .md-checkbox input[type=checkbox] {
          visibility: hidden;
          position: absolute; }
        .md-checkbox label {
          cursor: pointer;
          padding-left: 30px; }
        .md-checkbox label > span {
          display: block;
          position: absolute;
          left: 0;
          -webkit-transition-duration: 0.3s;
          -moz-transition-duration: 0.3s;
          transition-duration: 0.3s; }
        .md-checkbox label > span.inc {
          background: #fff;
          left: -20px;
          top: -20px;
          height: 60px;
          width: 60px;
          opacity: 0;
          border-radius: 50% !important;
          -moz-border-radius: 50% !important;
          -webkit-border-radius: 50% !important; }
        .md-checkbox label > .box {
          top: 0px;
          border: 2px solid #666;
          height: 20px;
          width: 20px;
          z-index: 5;
          -webkit-transition-delay: 0.2s;
          -moz-transition-delay: 0.2s;
          transition-delay: 0.2s; }
        .md-checkbox label > .check {
          top: -4px;
          left: 6px;
          width: 10px;
          height: 20px;
          border: 2px solid #36c6d3;
          border-top: none;
          border-left: none;
          opacity: 0;
          z-index: 5;
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          transform: rotate(180deg);
          -webkit-transition-delay: 0.3s;
          -moz-transition-delay: 0.3s;
          transition-delay: 0.3s; }
        .md-checkbox label > span.inc {
          -webkit-animation: growCircle 0.3s ease;
          -moz-animation: growCircle 0.3s ease;
          animation: growCircle 0.3s ease; }
        .md-checkbox input[type=checkbox]:checked ~ label > .box {
          opacity: 0;
          -webkit-transform: scale(0) rotate(-180deg);
          -moz-transform: scale(0) rotate(-180deg);
          transform: scale(0) rotate(-180deg); }
        .md-checkbox input[type=checkbox]:checked ~ label > .check {
          opacity: 1;
          -webkit-transform: scale(1) rotate(45deg);
          -moz-transform: scale(1) rotate(45deg);
          transform: scale(1) rotate(45deg); }
        .md-checkbox input[type=checkbox]:disabled ~ label,
        .md-checkbox input[type=checkbox][disabled] ~ label {
          cursor: not-allowed;
          opacity: 0.7 ;
          filter: alpha(opacity=70) ; }
        .md-checkbox input[type=checkbox]:disabled ~ label > .box,
        .md-checkbox input[type=checkbox][disabled] ~ label > .box {
          cursor: not-allowed;
          opacity: 0.7 ;
          filter: alpha(opacity=70) ; }
        .md-checkbox input[type=checkbox]:disabled:checked ~ label > .check,
        .md-checkbox input[type=checkbox][disabled]:checked ~ label > .check {
          cursor: not-allowed;
          opacity: 0.7 ;
          filter: alpha(opacity=70) ; }
      
      .has-error .md-checkbox label,
      .has-error.md-checkbox label {
        color: #e73d4a; }
      
      .has-error .md-checkbox label > .box,
      .has-error.md-checkbox label > .box {
        border-color: #e73d4a; }
      
      .has-error .md-checkbox label > .check,
      .has-error.md-checkbox label > .check {
        border-color: #e73d4a; }
      
      .has-success .md-checkbox label,
      .has-success.md-checkbox label {
        color: #27a4b0; }
      
      .has-success .md-checkbox label > .box,
      .has-success.md-checkbox label > .box {
        border-color: #27a4b0; }
      
      .has-success .md-checkbox label > .check,
      .has-success.md-checkbox label > .check {
        border-color: #27a4b0; }
      
      .has-warning .md-checkbox label,
      .has-warning.md-checkbox label {
        color: #c29d0b; }
      
      .has-warning .md-checkbox label > .box,
      .has-warning.md-checkbox label > .box {
        border-color: #c29d0b; }
      
      .has-warning .md-checkbox label > .check,
      .has-warning.md-checkbox label > .check {
        border-color: #c29d0b; }
      
      .has-info .md-checkbox label,
      .has-info.md-checkbox label {
        color: #327ad5; }
      
      .has-info .md-checkbox label > .box,
      .has-info.md-checkbox label > .box {
        border-color: #327ad5; }
      
      .has-info .md-checkbox label > .check,
      .has-info.md-checkbox label > .check {
        border-color: #327ad5; }
      
      .form-md-checkboxes {
        padding-top: 5px; }
        .form-md-checkboxes > label {
          font-size: 14px;
          color: #888888;
          opacity: 1 ;
          filter: alpha(opacity=100) ; }
        .form-md-checkboxes.has-error label {
          color: #ed6b75; }
        .form-md-checkboxes.has-info label {
          color: #659be0; }
        .form-md-checkboxes.has-success label {
          color: #36c6d3; }
        .form-md-checkboxes.has-warning label {
          color: #F1C40F; }
      
      .md-checkbox-list {
        margin: 5px 0 5px 0; }
        .form-horizontal .md-checkbox-list {
          margin-top: 5px; }
        .md-checkbox-list .md-checkbox {
          display: block;
          margin-bottom: 10px; }
          .md-checkbox-list .md-checkbox:last-child {
            margin-bottom: 0; }
      
      .md-checkbox-inline {
        margin: 5px 0 5px 0; }
        .form-horizontal .md-checkbox-inline {
          margin-top: 7px; }
        .md-checkbox-inline .md-checkbox {
          display: inline-block;
          margin-right: 20px; }
          .md-checkbox-inline .md-checkbox:last-child {
            margin-right: 0; }
          .datagridtable td, .datagridtable th {
            font-size:12px !important;
          }
          .datagridtable label {
            font-weight: normal !important;
          }
          .ql-container {
            box-sizing: border-box;
            font-family: Helvetica, Arial, sans-serif;
            font-size: 13px;
            height: 100%;
            margin: 0px;
            position: relative;
          }
          .ql-container.ql-disabled .ql-tooltip {
            visibility: hidden;
          }
          .ql-container.ql-disabled .ql-editor ul[data-checked] > li::before {
            pointer-events: none;
          }
          .ql-clipboard {
            left: -100000px;
            height: 1px;
            overflow-y: hidden;
            position: absolute;
            top: 50%;
          }
          .ql-clipboard p {
            margin: 0;
            padding: 0;
          }
          .ql-editor {
            box-sizing: border-box;
            line-height: 1.42;
            height: 100%;
            outline: none;
            overflow-y: auto;
            padding: 12px 15px;
            tab-size: 4;
            -moz-tab-size: 4;
            text-align: left;
            white-space: pre-wrap;
            word-wrap: break-word;
          }
          .ql-editor > * {
            cursor: text;
          }
          .ql-editor p,
          .ql-editor ol,
          .ql-editor ul,
          .ql-editor pre,
          .ql-editor blockquote,
          .ql-editor h1,
          .ql-editor h2,
          .ql-editor h3,
          .ql-editor h4,
          .ql-editor h5,
          .ql-editor h6 {
            margin: 0;
            padding: 0;
            counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
          }
          .ql-editor ol,
          .ql-editor ul {
            padding-left: 1.5em;
          }
          .ql-editor ol > li,
          .ql-editor ul > li {
            list-style-type: none;
          }
          .ql-editor ul > li::before {
            content: '2022';
          }
          .ql-editor ul[data-checked=true],
          .ql-editor ul[data-checked=false] {
            pointer-events: none;
          }
          .ql-editor ul[data-checked=true] > li *,
          .ql-editor ul[data-checked=false] > li * {
            pointer-events: all;
          }
          .ql-editor ul[data-checked=true] > li::before,
          .ql-editor ul[data-checked=false] > li::before {
            color: #777;
            cursor: pointer;
            pointer-events: all;
          }
          .ql-editor ul[data-checked=true] > li::before {
            content: '2611';
          }
          .ql-editor ul[data-checked=false] > li::before {
            content: '2610';
          }
          .ql-editor li::before {
            display: inline-block;
            white-space: nowrap;
            width: 1.2em;
          }
          .ql-editor li:not(.ql-direction-rtl)::before {
            margin-left: -1.5em;
            margin-right: 0.3em;
            text-align: right;
          }
          .ql-editor li.ql-direction-rtl::before {
            margin-left: 0.3em;
            margin-right: -1.5em;
          }
          .ql-editor ol li:not(.ql-direction-rtl),
          .ql-editor ul li:not(.ql-direction-rtl) {
            padding-left: 1.5em;
          }
          .ql-editor ol li.ql-direction-rtl,
          .ql-editor ul li.ql-direction-rtl {
            padding-right: 1.5em;
          }
          .ql-editor ol li {
            counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
            counter-increment: list-0;
          }
          .ql-editor ol li:before {
            content: counter(list-0, decimal) '. ';
          }
          .ql-editor ol li.ql-indent-1 {
            counter-increment: list-1;
          }
          .ql-editor ol li.ql-indent-1:before {
            content: counter(list-1, lower-alpha) '. ';
          }
          .ql-editor ol li.ql-indent-1 {
            counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
          }
          .ql-editor ol li.ql-indent-2 {
            counter-increment: list-2;
          }
          .ql-editor ol li.ql-indent-2:before {
            content: counter(list-2, lower-roman) '. ';
          }
          .ql-editor ol li.ql-indent-2 {
            counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
          }
          .ql-editor ol li.ql-indent-3 {
            counter-increment: list-3;
          }
          .ql-editor ol li.ql-indent-3:before {
            content: counter(list-3, decimal) '. ';
          }
          .ql-editor ol li.ql-indent-3 {
            counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
          }
          .ql-editor ol li.ql-indent-4 {
            counter-increment: list-4;
          }
          .ql-editor ol li.ql-indent-4:before {
            content: counter(list-4, lower-alpha) '. ';
          }
          .ql-editor ol li.ql-indent-4 {
            counter-reset: list-5 list-6 list-7 list-8 list-9;
          }
          .ql-editor ol li.ql-indent-5 {
            counter-increment: list-5;
          }
          .ql-editor ol li.ql-indent-5:before {
            content: counter(list-5, lower-roman) '. ';
          }
          .ql-editor ol li.ql-indent-5 {
            counter-reset: list-6 list-7 list-8 list-9;
          }
          .ql-editor ol li.ql-indent-6 {
            counter-increment: list-6;
          }
          .ql-editor ol li.ql-indent-6:before {
            content: counter(list-6, decimal) '. ';
          }
          .ql-editor ol li.ql-indent-6 {
            counter-reset: list-7 list-8 list-9;
          }
          .ql-editor ol li.ql-indent-7 {
            counter-increment: list-7;
          }
          .ql-editor ol li.ql-indent-7:before {
            content: counter(list-7, lower-alpha) '. ';
          }
          .ql-editor ol li.ql-indent-7 {
            counter-reset: list-8 list-9;
          }
          .ql-editor ol li.ql-indent-8 {
            counter-increment: list-8;
          }
          .ql-editor ol li.ql-indent-8:before {
            content: counter(list-8, lower-roman) '. ';
          }
          .ql-editor ol li.ql-indent-8 {
            counter-reset: list-9;
          }
          .ql-editor ol li.ql-indent-9 {
            counter-increment: list-9;
          }
          .ql-editor ol li.ql-indent-9:before {
            content: counter(list-9, decimal) '. ';
          }
          .ql-editor .ql-indent-1:not(.ql-direction-rtl) {
            padding-left: 3em;
          }
          .ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
            padding-left: 4.5em;
          }
          .ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
            padding-right: 3em;
          }
          .ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
            padding-right: 4.5em;
          }
          .ql-editor .ql-indent-2:not(.ql-direction-rtl) {
            padding-left: 6em;
          }
          .ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
            padding-left: 7.5em;
          }
          .ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
            padding-right: 6em;
          }
          .ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
            padding-right: 7.5em;
          }
          .ql-editor .ql-indent-3:not(.ql-direction-rtl) {
            padding-left: 9em;
          }
          .ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
            padding-left: 10.5em;
          }
          .ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
            padding-right: 9em;
          }
          .ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
            padding-right: 10.5em;
          }
          .ql-editor .ql-indent-4:not(.ql-direction-rtl) {
            padding-left: 12em;
          }
          .ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
            padding-left: 13.5em;
          }
          .ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
            padding-right: 12em;
          }
          .ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
            padding-right: 13.5em;
          }
          .ql-editor .ql-indent-5:not(.ql-direction-rtl) {
            padding-left: 15em;
          }
          .ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
            padding-left: 16.5em;
          }
          .ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
            padding-right: 15em;
          }
          .ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
            padding-right: 16.5em;
          }
          .ql-editor .ql-indent-6:not(.ql-direction-rtl) {
            padding-left: 18em;
          }
          .ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
            padding-left: 19.5em;
          }
          .ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
            padding-right: 18em;
          }
          .ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
            padding-right: 19.5em;
          }
          .ql-editor .ql-indent-7:not(.ql-direction-rtl) {
            padding-left: 21em;
          }
          .ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
            padding-left: 22.5em;
          }
          .ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
            padding-right: 21em;
          }
          .ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
            padding-right: 22.5em;
          }
          .ql-editor .ql-indent-8:not(.ql-direction-rtl) {
            padding-left: 24em;
          }
          .ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
            padding-left: 25.5em;
          }
          .ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
            padding-right: 24em;
          }
          .ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
            padding-right: 25.5em;
          }
          .ql-editor .ql-indent-9:not(.ql-direction-rtl) {
            padding-left: 27em;
          }
          .ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
            padding-left: 28.5em;
          }
          .ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
            padding-right: 27em;
          }
          .ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
            padding-right: 28.5em;
          }
          .ql-editor .ql-video {
            display: block;
            max-width: 100%;
          }
          .ql-editor .ql-video.ql-align-center {
            margin: 0 auto;
          }
          .ql-editor .ql-video.ql-align-right {
            margin: 0 0 0 auto;
          }
          .ql-editor .ql-bg-black {
            background-color: #000;
          }
          .ql-editor .ql-bg-red {
            background-color: #e60000;
          }
          .ql-editor .ql-bg-orange {
            background-color: #f90;
          }
          .ql-editor .ql-bg-yellow {
            background-color: #ff0;
          }
          .ql-editor .ql-bg-green {
            background-color: #008a00;
          }
          .ql-editor .ql-bg-blue {
            background-color: #06c;
          }
          .ql-editor .ql-bg-purple {
            background-color: #93f;
          }
          .ql-editor .ql-color-white {
            color: #fff;
          }
          .ql-editor .ql-color-red {
            color: #e60000;
          }
          .ql-editor .ql-color-orange {
            color: #f90;
          }
          .ql-editor .ql-color-yellow {
            color: #ff0;
          }
          .ql-editor .ql-color-green {
            color: #008a00;
          }
          .ql-editor .ql-color-blue {
            color: #06c;
          }
          .ql-editor .ql-color-purple {
            color: #93f;
          }
          .ql-editor .ql-font-serif {
            font-family: Georgia, Times New Roman, serif;
          }
          .ql-editor .ql-font-monospace {
            font-family: Monaco, Courier New, monospace;
          }
          .ql-editor .ql-size-small {
            font-size: 0.75em;
          }
          .ql-editor .ql-size-large {
            font-size: 1.5em;
          }
          .ql-editor .ql-size-huge {
            font-size: 2.5em;
          }
          .ql-editor .ql-direction-rtl {
            direction: rtl;
            text-align: inherit;
          }
          .ql-editor .ql-align-center {
            text-align: center;
          }
          .ql-editor .ql-align-justify {
            text-align: justify;
          }
          .ql-editor .ql-align-right {
            text-align: right;
          }
          .ql-editor.ql-blank::before {
            color: rgba(0,0,0,0.6);
            content: attr(data-placeholder);
            font-style: italic;
            left: 15px;
            pointer-events: none;
            position: absolute;
            right: 15px;
          }
    </style>`
  let getHTMLContent = `<!DOCTYPE html><html><head><meta name="viewport" content="width=device-width" />
    <meta charset="utf-8"/>${styleLink}</head>
    <body><br>`
    if (!dataGridShow) {
      getHTMLContent += `<div class="container">`
    }
    if (!dataGridShow) {
      getHTMLContent += `<table class="table table-bordered">
        <tbody>
          <tr height="80px">
            <td align="center" style="border: none !important;vertical-align: middle !important;" width="100%">
              <div style="width: 85%;float: left;margin: 1rem 0;">
                <font size="4">
                  <b>
                    Sjekkliste: ${form_name !==null ? form_name :  ''}
                  </b>
                </font>
              </div>
              <div style="width: 15%;float: right;display: flex;flex-direction: row-reverse;">`
              if(logoBase64.trim().length) {
                getHTMLContent += `<img src="${logoBase64}" alt="" class="img-responsive" width="100%">`
              }
              getHTMLContent += `</div>
            </td>
          </tr>
        </tbody>
        </table>`
      }
      // `<div class="panel panel-default">`
      if (dataGridShow) {
        // getHTMLContent += `<div class="panel-body">
        // ${dataGrid(fields, model, status_list, checklilstName, projectdata, allTransData)}
        // </div>`
        getHTMLContent += `${dataGrid(fields, model, status_list, checklilstName, projectdata, allTransData)}`
      } else {
        `<div class="panel panel-default">`
        getHTMLContent += `<div class="panel-body">
        ${pdf_data(fields, model, status_list, checklilstName, projectdata, allTransData)}
        </div> </div>`     
      } 
      if (!dataGridShow) {
        getHTMLContent += `</div>`
      }
      getHTMLContent +=`</body></html>`
    return getHTMLContent
  // return btoa(unescape(encodeURIComponent(getHTMLContent)))
}
const pdf_data = (fields, model, status_list, checklilstName='', projectdata = '', allTransData) => {
  let htmlData = ''
  let colCount = 0
  let canAddOpenRow = true
    if(checklilstName) {
      htmlData +=`<div class="row custom-row-margin">`
      htmlData +=`<div class="col-xs-12 div-padding " style="margin-bottom:10px">`
      htmlData += `<label>${checklilstName || ''}</label>`
      htmlData +=`</div>`
      htmlData +=`</div>`
    }
    if (projectdata.is_project_data) {
      htmlData +=`<div class="row custom-row-margin" style="width:100%;float:left;">`
      Object.entries(projectdata).forEach(function(label, value){

            if(label[0] != 'is_project_data') {

              htmlData +=`<div class="col-xs-12 div-padding " style="margin-bottom:10px; width:50%; float:left;">`
              htmlData += `<label>${label[0] || ''} </label>: <span style="white-space: pre-wrap" class="textWrap"> ${label[1] || ''}</span>`
              htmlData +=`</div>`
            }

      });
      htmlData +=`</div>`

    }

if(fields) {
  for (let i = 0; i < fields.length; i++) {
    if (!fields[i].default_value) fields[i].default_value = {}
    if (!fields[i].default_value.options) fields[i].default_value.options = []
    if (fields[i].isPanelField) htmlData += ''
    else {
      fields[i].label = fields[i].label || ''
      /* working on chaning the things to have column from the field column */
      fields[i].columns = fields[i].columns || 12
      let nextColCount = colCount + fields[i].columns
      if ((colCount >= 12 || nextColCount > 12) || [18,19].includes(fields[i].type))
      {
          colCount = 0
          htmlData += '</div>'
          canAddOpenRow = true
      }
      if (canAddOpenRow) {
        htmlData += `<div class="row ${fields[i].type === 19 ? 'row-margin-zero': 'custom-row-margin'}">`
          canAddOpenRow = false
      }
      colCount += fields[i].columns
      /* --- */
      htmlData += `<div class="col-xs-${fields[i].columns} div-padding">`
      fields[i].default_value.options = fields[i].default_value.options || []
      switch (fields[i].type) {
          case 3: { // CHECKBOXES
            let values = fields[i].default_value.options.filter(x => x.isactive || x.isactive === undefined)
          let viewType = fields[i].default_value.viewType || 'default'
          if (viewType === 'horizontal') viewType = 'default'
          /* View type Horizontal  & Vertical */
          if (fields[i].label) htmlData += `<label>${fields[i].label || ''}</label>`
          let cb = `<input type="checkbox" />`
          let cbChecked = `<input type="checkbox" checked />`
          htmlData += `<div class="md-checkbox-inline">`
          switch (viewType) {
            case 'vertical':
              for (let j = 0; j < values.length; j++) {
                htmlData += `<div class="md-checkbox">`
                if (model && model[fields[i].name] && model[fields[i].name].find(x => x === values[j].value))
                    htmlData += `<input type="checkbox" id="" class="md-check" checked>`
                  else
                    htmlData += `<input type="checkbox" id="" class="md-check">`
                    htmlData += `<label for="checkbox6"><span class="inc"></span><span class="check"></span><span class="box"></span> ${values[j].label || ''}</label>`
                htmlData += `</div>`

              }
              break
            case 'default':
             // htmlData += `<p class="custom-margin">`
              for (let j = 0; j < values.length; j++) {
                htmlData += `<div class="md-checkbox">`
                if (model && model[fields[i].name] && model[fields[i].name].find(x => x === values[j].value))
                    htmlData += `<input type="checkbox" id="" class="md-check" checked>`
                  else
                    htmlData += `<input type="checkbox" id="" class="md-check">`
                    htmlData += `<label for="checkbox6"><span class="inc"></span><span class="check"></span><span class="box"></span> ${values[j].label || ''}</label>`
                htmlData += `</div>`
              }
              //htmlData += `</p>`
              break
          }
          htmlData +=`</div>`
          break
          }
          case 4: { // RADIO BUTTONS
              let values = fields[i].default_value.options.filter(x => x.isactive || x.isactive === undefined)
              if (fields[i].label) htmlData += `<label>${fields[i].label || ''} </label><br>`
              htmlData +=`<div class="md-radio-inline">`
              for (let j = 0; j < values.length; j++) {
                htmlData +=`<div class="md-radio">`
                if (model && values[j].value === model[fields[i].name])
                  htmlData +=`<input type="radio" id="${fields[i].name}" name="${fields[i].name}" class="md-radiobtn" checked>`
                else
                  htmlData +=`<input type="radio" id="${fields[i].name}" name="${fields[i].name}" class="md-radiobtn" >`
                  htmlData +=`<label for="${fields[i].name}"><span></span><span class="check"></span> <span class="box"></span> ${values[j].label || ''}</label>`
                  htmlData +=`</div>`
              }
              htmlData +=`</div>`
              break
          }
          case 5: { // SELECTS LIST
              let options = fields[i].default_value.options.filter(x => x.isactive || x.isactive === undefined)
              let isMultiSelect = fields[i].default_value.is_multiselect
              let select = model && model[fields[i].name] || []
              if (fields[i].label) htmlData += `<label>${fields[i].label} </label>`
              if (isMultiSelect) {
              let multiItems = []
              for (let k = 0; k < select.length; k++) {
                  let getItem = options.find(x => x.value === select[k])
                  let getText = getItem ? getItem.label : ''
                  multiItems.push(getText)
              }
              htmlData += `<span style="white-space: pre-wrap" class="textWrap"> ${multiItems.join(', ')}</span>`
              } else if (fields[i].default_value.isAPI) {
              htmlData += `<span style="white-space: pre-wrap" class="textWrap"> ${model && model[fields[i].name] || ''} </span>`
              } else {
                  if (options) {
                      let getItem = options.find(x => x.value === select)
                      let getText = getItem ? getItem.label : ''
                      htmlData += `<span style="white-space: pre-wrap" class="textWrap"> ${getText || ''}</span>`
                  }
              }
              break
          }
          case 8: { // SWITCH
              let checked = ''
              if (model && model[fields[i].name]) checked = `<input type="checkbox" checked />`
              else checked = `<input type="checkbox" />`
              htmlData += `
              <label>${fields[i].label} </label>
              <label class="switch">
                  ${checked}
                  <span class="slider round"></span>
              </label>`
              break
          }
          case 9: 
              htmlData += `<label>${fields[i].label} </label> <span style="white-space: pre-wrap" class="textWrap">${allTransData? allTransData.attachmentsBelow : ''}</span>
              <svg style="display:none;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-circle" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
              </svg>`
              break
          case 10: { // Survey COMPONENT // No design change do for this one
              let value = model[fields[i].name] ? model[fields[i].name]['answer'] : ''
              let options = fields[i].default_value.options.filter(x => x.isactive || x.isactive === undefined) || []
              let getItem = options.find(x => x.value === value)
              let getText = getItem ? getItem.label : ''
              let isHideComments = fields[i].default_value.hideComments
              htmlData += `<table class="table table-bordered" style="width: 100%">
                  <tbody>
                      <tr>
                      <th width="${isHideComments ? '65' : '40'}%">Emne</th>
                      <th width="${isHideComments ? '35' : '20'}%">Valg</th>`
              if (!isHideComments) htmlData += `<th width="40%">Kommentar</th>`
              htmlData += `</tr>
                  </thead>
                  <tbody>
                      <tr>
                      <td>${fields[i].label}</td>
                      <td>${getText}</td>`
              if (!isHideComments) htmlData += `<td>${model[fields[i].name] ? model[fields[i].name]['comments'] : ''}</td>`
              htmlData += `</tr>
                  </tbody>
                  </table>`
              break
          }
          case 11: { // DIVIDER
              htmlData += `<div style="padding:.35rem .25rem;border-bottom:1px solid;"><strong>${fields[i].label || ''}</strong></div>`
              break
          }
          case 12: { // GRID
              htmlData += `<label>${fields[i].label} </label> NOT IMPLEMENTED`
              break
          }
          case 16: {
              let defaultValue = fields[i].default_value
              defaultValue.html_content
              htmlData += `<div style="padding: 0.25rem; background-color: #f7f7f7;margin-bottom:5px">${defaultValue.html_content}</div>`
              break
          }
          case 18: {
              let defaultValue = fields[i].default_value
              if (defaultValue.grid_data && defaultValue.grid_data.labels) { // && (defaultValue.grid_data.labels.length < 6
              htmlData += `${dataGridReportDataConstruct(defaultValue.grid_data.labels, model[fields[i].name], fields[i].showSum, fields[i])}`
              }
              break
          }
          case 13: { // SIGNATURE
              htmlData += `<label>${fields[i].label} </label> <img src="${model && model[fields[i].name]}" class="img-responsive signature_13"/>`
              break
          }
          case 14: { // GRID
            htmlData += `<label>${fields[i].label || ''} </label>`
            htmlData += `${pdf_data(fields[i].panel_fields, model, status_list)}`
            break
          }
          case 19: { // SURVEY COMPONENT
              let values = model && model[fields[i].name] || {}
          let options = fields[i].default_value.options.filter(x => x.isactive || x.isactive === undefined) || []
          let { selectionType } = fields[i].default_value.surveyOptions ? fields[i].default_value.surveyOptions : 0
          selectionType = selectionType ? selectionType : 0 // 0 is radio and 1 is checkbox
          let getText = ''
          if (selectionType === 1) {
            let getItems = options.filter(x => values.answer ? values.answer.includes(x.value) : false)
            if (getItems.length) getText = getItems.map(x => x.label).join(', ')
          } else if (selectionType === 0) {
            let getItem = options.find(x => x.value === values.answer || '')
            getText = getItem ? getItem.label : ''
          }
          let checkboxFlags = fields[i].default_value.checkboxFlags
          let signAndDate = ''
          let date = ''
          if (checkboxFlags.signature) signAndDate += values.signature || ''
          if (checkboxFlags.date) date += values.date || ''
          // if ((fields[i].type === 19 && fields[(i-1 == -1 ? 0 : i-1)].type !== 19) || i === 0) {
          //   htmlData +=  `<div class="ql-editor">${fields[i].html }</div>`
          // }
          htmlData += `<table style="margin-bottom: 20px; border: 1px solid #dddddd;" width="100%">`
          if (fields[i] && fields[i].html && fields[i].html !== '' ) {
            htmlData +=`<thead><tr bgcolor="#f5f5f5">
                          <th colspan="4" class="ql-editor"><div>${fields[i].html ? fields[i].html : ''}</div></th>
                        </tr></thead>`
          }
          htmlData += `<tbody><tr style="margin-top: 20px"> 
                  <td width="40%" style="padding: 12px"> ${fields[i].label || ''}</td>`
                    htmlData +=`<td width="30%" style="margin-top: 20px">`
                    if (fields && fields[i].default_value &&  fields[i].default_value.surveyOptions && fields[i].default_value.surveyOptions.selectionType === 1) {
                      htmlData += `<div class="md-checkbox-inline">`
                      for (let j = 0; j < options.length; j++) {
                        htmlData += `<div class="md-checkbox" style="margin: 7px">`
                        if (model && model[fields[i].name] && model[fields[i].name].answer && Array.isArray(model[fields[i].name].answer) && model[fields[i].name].answer.find(x => x === options[j].value))
                          htmlData += `<input type="checkbox" id="" class="md-check" checked>`
                        else
                          htmlData += `<input type="checkbox" id="" class="md-check">`
                          htmlData += `<label for="checkbox6"><span class="inc"></span><span class="check"></span><span class="box"></span> ${options[j].label || ''}</label>`
                          htmlData += `</div>`
                        }
                      htmlData +=`</div>`
                    } else {
                      for (let j = 0; j < options.length; j++) {
                        htmlData +=`<span class="md-radio" style="margin: 10px">`
                        if (model && model[fields[i].name] && model[fields[i].name].answer && options[j].value === model[fields[i].name].answer)
                          htmlData +=`<input type="radio" class="md-radiobtn" checked>`
                        else 
                          htmlData += `<input type="radio" id="${options[j].value}" name="${options[j].value}" class="md-radiobtn">`
                          htmlData += `<label for="${options[j].value}"><span class="check"></span><span class="box"></span> ${options[j].label || ''}</label>`
                          htmlData += `</span>`
                      }
                    }
                    htmlData +=`</td>`
                htmlData += `<td width="15%" style="padding: 12px">`
                if (signAndDate) htmlData +=`<span style="font-size: 14px;">${signAndDate}</span>`
                htmlData +=`</td>`
                htmlData +=`<td width="15%" style="padding: 12px">`
              if (date) htmlData +=`<span class="sign-date">${date}</span>`
               htmlData +=`</td>` 
              htmlData += '</tr>'
              if ((model && model[fields[i].name] && model[fields[i].name].comments) && fields[i].default_value && !fields[i].default_value.hideComments) {
                htmlData += `<tr style="margin-top: 20px">
                  <td colspan="4" style="text-align: justify; padding: 12px">
                    <div class="custom-margin h4"> 
                      <span style="font-weight: bold"> Kommentar: </span> 
                      <p>
                      ${(model && model[fields[i].name] && model[fields[i].name].comments) ? nl2br(model[fields[i].name].comments) : ''}
                      </p>
                    </div>
                  </td>
                </tr>`
              }   
          htmlData +=`</tbody></table>`

              // let values = model[fields[i].name] || {}
              // let options = fields[i].default_value.options.filter(x => x.isactive || x.isactive === undefined) || []
              // let { selectionType } = fields[i].default_value.surveyOptions
              // selectionType = selectionType ? selectionType : 0 // 0 is radio and 1 is checkbox
              // let getText = ''
              // if (selectionType === 1) {
              //     let getItems = options.filter(x => values.answer.includes(x.value))
              //     if (getItems.length) getText = getItems.map(x => x.label).join(', ')
              // } else if (selectionType === 0) {
              //     let getItem = options.find(x => x.value === values.answer || '')
              //     getText = getItem ? getItem.label : ''
              // }
              // let checkboxFlags = fields[i].default_value.checkboxFlags
              // let signAndDate = ''
              // if (checkboxFlags.signature) signAndDate += values.signature || ''
              // if (checkboxFlags.date) signAndDate += (checkboxFlags.signature ? ' - ' : '') + values.date || ''
              // if ((fields[i].type === 19 && fields[(i-1 == -1 ? 0 : i-1)].type !== 19) || i === 0) {
              //   htmlData +=  `<div class="ql-editor">${fields[i].html }</div>`
              // }
              // htmlData += `<table class="table table-bordered table-padding-adjusted" style="width: 100%">`
              // if ((fields[i].type === 19 && fields[(i-1 == -1 ? 0 : i-1)].type !== 19) || i === 0) {
              //   htmlData +=  `<thead>
              //         <tr>
              //           <th width="35%">Emne</th>
              //           <th width="5%">Valg</th>
              //           <th width="60%">Kommentar</th>
              //         </tr>
              //     </thead><tbody>`
              // }
              // htmlData +=`<tr>
              //     <td width="35%">${fields[i].label ? fields[i].label : ''}</td>
              //     <td width="5%">${getText ? getText : ''}</td>
              //     <td width="60%">
              //         <div style="width:100%">
              //           <h5 style="margin:0 !important;">
              //             <span style="width:49.5%;float:left;display:none;">
              //               <strong>Comment</strong>
              //             </span> 
              //             <span style="width:49.5%;float:right;" class="badge badge-info sign-date">${signAndDate}</span>
              //             <div class="h4 custom-margin"><span style="white-space: pre;" class="textWrap"> ${model[fields[i].name].comments ? nl2br(model[fields[i].name].comments) : ''}</span> </div>
              //           </h5>
              //         </div>
              //     </td>
              //     </tr>`
              // htmlData +=`</tbody></table>`
              break
          }
          case 2:
            htmlData += `<label>${fields[i].label || ''} </label> <span style="white-space: pre;" class="textWrap ql-editor"> ${model && model[fields[i].name] ? nl2br(model[fields[i].name]) : ''}</span>`
          break
          default:
            htmlData += `<label>${fields[i].label} </label>`
          if(fields[i].type === 6) htmlData += `<br/>`
            htmlData +=`<span style="white-space: pre-wrap" class="textWrap"> ${model && model[fields[i].name] || ''}</span>`
          break
      }
      htmlData += `</div>`
    }
  }
}
  // document.getElementById("PDFContent").innerHTML = htmlData
  return htmlData
}
/* S Component - 19 */
const getCols = (flagObj) => { // for component 19
  return (flagObj.signature || flagObj.date) ? (flagObj.signature && flagObj.date) ? 3 : 2 : 1
}
/* --- */
const getGridReportData = (gridList, tableHeader) => {
  let headers = gridList
  let gridHeadReport = '<div><table class="table table-bordered"><tr>'
  for (let x in tableHeader) {
    gridHeadReport += `<th style="height: 30px">${tableHeader[x].label}</th>`
  }
  gridHeadReport += `</tr>`
  for (let i in headers) {
    gridHeadReport += `<tr>`
    for (let j in headers[i]) {
      gridHeadReport += `<td style="height: 30px">${headers[i][j].value}</td>`
    }
    gridHeadReport += `</tr>`
  }
  gridHeadReport += `</table></div>`
  return `${gridHeadReport}`
}
const dataGridReportDataConstruct = (lables, dataRows, showSum, fields) => {
  if (!dataRows) dataRows = []
  let table = '<table class="table table-bordered datagridtable" style="padding: 5px;"><thead><tr>'
  /* For headers */
  lables.sort((a, b) => Number(a.position) - Number(b.position))
  lables.forEach((labelObj) => {
    table += `<th width="${labelObj.pixels}px !important">${labelObj.label}</th>`
  })
  table += '</tr></thead>'
  table += '<tbody>'
  Object.values(dataRows).forEach((rowData, rowIndex) => {
    table += '<tr>'
    lables.forEach((tdObj, tdIndex) => {
      switch (tdObj.field.type) {
        /* case 'string':
        case 'number':
        case 'date':
        case 'label':
          break */
        case 'checkbox':
          let checkboxValues = rowData[tdIndex].value
          let cvalues = tdObj.field.typedata.listItems || []
          // table += `<td style="min-width:100px;">`   /* <label>${tdObj.label} </label> */
          // table += `<div class="md-checkbox-inline">`
          // for (let j = 0; j < cvalues.length; j++) {
          //   table += `<div class="md-checkbox">`
          //   if (checkboxValues && checkboxValues.find(x => x === cvalues[j].value))
          //     table += `<input type="checkbox" class="md-check" checked>`
          //   else
          //     table += `<input type="checkbox" class="md-check">`
          //     table += `<label for="checkbox6"><span class="inc"></span><span class="check"></span><span class="box"></span>  ${cvalues[j].label ? cvalues[j].label : ''}</label>`
          //     table += `</div>`
          // }
          table += `<td style="min-width: 100px;">`/* <label>${tdObj.label} </label> */
          table += `<div class="md-checkbox-inline">`
          for (let j = 0; j < cvalues.length; j++) {
            if (tdObj.field.typedata.dataGridCheckboxOnlyValue) {
              table += `<div>`
              if (checkboxValues && checkboxValues.find(x => x === cvalues[j].value))
              table += `<label for="checkbox6"><span class="inc"></span><span class="check"></span><span class="box"></span> ${(cvalues[j] && cvalues[j].label) ? cvalues[j].label : ''}</label>`
              table += `</div>`
            } else {
              table += `<div class="md-checkbox">`
              if (checkboxValues && checkboxValues.find(x => x === cvalues[j].value))
                table += `<input type="checkbox" class="md-check" checked>`
              else
                table += `<input type="checkbox" id="" class="md-check">`
              table += `<label for="checkbox6"><span class="inc"></span><span class="check"></span><span class="box"></span> ${(cvalues[j] && cvalues[j].label) ? cvalues[j].label : ''}</label>`
              table += `</div>`
            }
          }
          table += `</div>`
          table += `</td>`
          break
        case 'radiobox':
          let rvalues = tdObj.field.typedata.listItems || []
          table += `<td style="min-width:100px;">` /* <div class="col-xs-12"><label>${fields[i].label} </label> */
          table +=`<div class="md-radio-inline">`
          for (let j = 0; j < rvalues.length; j++) {
            // table +=`<div class="md-radio">`
            // if (rvalues[j].value === rowData[tdIndex].value) table +=`<input type="radio" style="width:120px;" id="${rowIndex}_${rvalues[j].name}" name="${rowIndex}_${rvalues[j].name}" class="md-radiobtn" checked>`
            // else table +=`<input type="radio" style="width:120px;" id="${rvalues[j].name}" name="${rvalues[j].name}" class="md-radiobtn">`
            // table +=`<label for="${rvalues[j].name}"> <span></span><span class="check"></span><span class="box"></span> ${rvalues[j].label || ''}</label>`
            // table +=`</div>`
            if (tdObj.field.typedata.dataGridCheckboxOnlyValue) {
              table +=`<div>`
              if (rvalues[j].value === rowData[tdIndex].value) 
              table +=`<label for="${rvalues[j].label}"><span></span><span class="check"></span><span class="box"></span> ${rvalues[j].label || ''}</label>`
              table +=`</div>`
            } else {
              table +=`<div class="md-radio">`
              if (rvalues[j].value === rowData[tdIndex].value) 
                table +=`<input type="radio" id="${rowIndex}_${tdIndex}_${rvalues[j].label}_${fields.name}" name="${rowIndex}_${tdIndex}_${rvalues[j].label}_${fields.name}" class="md-radiobtn" checked>`
              else table +=`<input type="radio" id="${rvalues[j].label}_${fields.name}" name="${rvalues[j].label}_${fields.name}" class="md-radiobtn">`
              table +=`<label for="${rvalues[j].label}"><span></span><span class="check"></span><span class="box"></span> ${rvalues[j].label || ''}</label>`
              table +=`</div>`
            }
          }
          table += `</td>`
          break
        case 'select':
          let options = tdObj.field.typedata.listItems || []
          let isMultiSelect = tdObj.field.typedata.isMultiSelect || false
          let select = rowData[tdIndex].value || []
          table += `<td style="min-width:100px;"><div class="col-xs-12">`/* <label>${fields[i].label} </label> */
          if (isMultiSelect) {
            let multiItems = []
            for (let k = 0; k < select.length; k++) {
              let getItem = options.find(x => x.value === select[k])
              let getText = getItem ? getItem.label : ''
              multiItems.push(getText)
            }
            table += `<span style="white-space: pre-wrap"> ${multiItems.join(', ')}</span>`
          } else {
            if (options) {
              let getItem = options.find(x => x.value === select)
              let getText = getItem ? getItem.label : ''
              table += `<span style="white-space: pre-wrap"> ${getText || ''}</span>`
            }
          }
          table += `</div></td>`
          break
        case 'label':
          table += `<td style="min-width:100px;">`
          if (tdObj.field.typedata.calculatingValue && tdObj.field.typedata.insertPosition === 'prefix') table += `<span>${tdObj.field.typedata.calculatingValue * (rowIndex + 1)} </span>`
          table += `${tdObj.field.typedata.labeltext || ''}`
          if (tdObj.field.typedata.calculatingValue && tdObj.field.typedata.insertPosition === 'suffix') table += ` <span>${tdObj.field.typedata.calculatingValue * (rowIndex + 1)}</span>`
          table += `</td>`
          break
        case 'textarea': 
          table += `<td style="" class="textWrap"> ${nl2br(rowData[tdIndex].value)}</td>`
          break
        default:
          table += `<td style="min-width:100px;">${rowData[tdIndex].value}</td>`
          break
      }
    })
    table += '</tr>'
  })
  if (showSum.showSumRow && !isEmptyObject(dataRows)) {
    table += '<tr>'
    lables.forEach((sumColumn, sumIndex) => {
      table += '<th>'
      if (showSum.sumColIndexes.includes(sumIndex)) table += `${getGridDataValuesTotal(sumIndex, dataRows)}`
      table += `</th>`
    })
    table += '</tr>'
  }
  table += '</tbody></table>'
  return table
}
const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}
const getGridDataValuesTotal = (sumIndex, data) => {
  let total = 0
  let rows = Object.keys(data)
  let allRowValues = []
  rows.forEach(key => {
    var obj = data[key].find((x, y) => y === sumIndex)
    if (obj) allRowValues.push(parseFloat(obj.value || 0))
  })
  total = getSum(allRowValues)
  return total
  // return replaceDotWithComma(total)
}
const getSum = (arr) => {
  return [...arr].reduce((acc, val) => acc + val, 0)
}
const dataGrid = (fields, model, status_list, checklilstName='', projectdata='', allTransData) => {
  let htmlData = ''
  if(fields) {
    for (let i = 0; i < fields.length; i++) {
      let defaultValue = fields[i].default_value
      if (defaultValue.grid_data && defaultValue.grid_data.labels) { // && defaultValue.grid_data.labels.length > 5
        htmlData += `${dataGridReportDataConstruct(defaultValue.grid_data.labels,model && model[fields[i].name], fields[i].showSum, fields[i])}`
      }
    }
  }
  return htmlData
}
const nl2br = (str, is_xhtml) => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}


export { generate_pdf_content }
