<template>
  <v-card class="pt-2">
    <template v-if="!payload.hideHeader">
      <v-card-title class="pa-0 pl-2 pr-2">
        <v-btn v-if="payload.hasPagination" x-small fab @click="previousList" :disabled="payload.pagination.page === 1">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn color="primary" v-if="payload.addClickHandler && payload.hasPermission" fab x-small id="newbtn" @click="payload.addClickHandler" class="ml-3">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-menu offset-y v-if="payload.isTickets && payload.hasPermission">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" v-on="on" fab x-small id="ticket_actions" class="ml-3"><v-icon>mdi-dots-horizontal</v-icon></v-btn><!-- {{$t('message.tickets.actions')}} -->
          </template>
          <v-list dense>
            <v-list-item v-for="(item, index) in menuList" :key="index" @click="payload.menuActions(item.value)">
              <v-list-item-icon><v-icon v-text="item.icon" medium></v-icon></v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn color="primary" v-if="payload.isCustomModule && payload.relateClickHandler  && payload.hasPermission" fab x-small class="ml-3" id="relatebtn" @click="payload.relateClickHandler">
          <v-icon>mdi-swap-horizontal</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <h5><span></span> {{ $t(payload.moduleTitle) }} {{ $t('message.checklist.title') }}</h5><!-- <v-icon>{{payload.moduleIcon}}</v-icon> {{ payload.moduleTitle }}-->
        <v-spacer></v-spacer>
        <span class="mx-3 subtitle-2">{{ payload.list.length }} {{ $t('message.common.of') }}  {{ payload.total }}</span>
        <v-btn fab class="mr-2" dark x-small color="primary" v-if="payload.hasFilter" @click="toggleFilter">
          <v-icon dark>mdi-filter</v-icon>
        </v-btn>
        <v-btn fab class="mr-2" dark x-small color="primary" v-if="payload.refreshHandler" @click="payload.list = [], payload.total = 0, payload.startPage = 0, payload.refreshHandler()">
          <v-icon dark>mdi-reload</v-icon>
        </v-btn>
        <v-btn v-if="payload.hasPagination" x-small fab @click="nextList" :disabled="hasNoNext || payload.hasNoNext">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-title class="px-3 pt-2 py-0" v-if="payload.hasSearch">
        <v-text-field outlined :label="$t('message.common.search')" class="pa-0" v-model="searchTerm" hide-details dense></v-text-field>
      </v-card-title>
    </template>
    <v-virtual-scroll v-show="payload.list.length" :items="payload.list" :item-height="'85'" max-height="65vh"  :id="id">
      <template v-slot:default="{ item }">
        <v-card-text class="pa-1 pt-0">
          <template v-if="payload.isTickets">
            <v-list two-line>
              <v-list-item-group v-model="selectedTickets" multiple>
                <template>
                  <v-list-item :key="`${index}_record`" :value="record" class="custom_list_style">
                    <template v-slot:default="{ active }">
                      <v-list-item-avatar size="20" class="avatar_custom_margin">
                        <v-icon size="20" color="primary" v-text="payload.moduleIcon"></v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-1">
                        <p class="mb-0" v-for="(record, index) in payload.items" :key="index" :class="index === 0 ? 'subtitle-2 font-weight-medium' : 'font-weight-regular'">{{item[record.value]}}</p>
                      </v-list-item-content>
                      <v-list-item-action class="list_action_custom_margin">
                        <v-layout row wrap class="mx-0">
                          <v-icon class="mr-2" color="primary lighten-1" v-if="payload.viewClickHandler" @click.stop="payload.viewClickHandler(record)">mdi-eye</v-icon>
                          <v-icon class="mr-3" v-if="!active" color="grey lighten-1">mdi-checkbox-multiple-blank-circle-outline</v-icon>
                          <v-icon class="mr-3" v-else color="info">mdi-checkbox-multiple-blank-circle</v-icon>
                        </v-layout>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </template>
          <template v-else-if="payload.list.length > 0">
            <template>
              <v-card outlined class="pa-1 mt-1" @click="payload.editHandler(item)">
                <v-card-text class="pa-1 pt-0 pb-0" :style="{'background-color':'#e8eaf6', 'min-height': '75px' }">
                  <v-row>
                    <v-col cols="7" sm="8" md="9" lg="9" xs="6" class="pa-1 pb-0">
                      <v-row class="mt-n1">
                        <v-col cols="11" class="pa-0">
                           <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <p v-on="on" class="mb-0" v-for="(record, index) in payload.items" :key="index"
                                :class="index === 0 ? 'subtitle-2 font-weight-medium mt-1' : 'font-weight-regular'"
                                :style="{ 'white-space': index === 0 ? 'nowrap' : 'normal',  'overflow': index === 0 ? 'hidden' : 'visible', 'text-overflow' : 'ellipsis'}">
                                  {{ item[record.value] }}
                                </p>
                              </template>
                              <span v-for="(record, index) in payload.items" :key="index"> <span v-if="index === 0"> {{ item[record.value] }}</span> </span>
                            </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="5" sm="4" md="3" lg="3" class="pa-1" :class="($vuetify.breakpoint.xs || $vuetify.breakpoint.xs) ? '' : ' text-right'">
                      <v-btn icon color="primary" v-if="payload.copyChecklist" id="copybtn" @click.stop="payload.copyChecklist(item)">
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                      <v-btn icon color="primary" v-if="payload.isCustomModule" id="unrelatebtn" @click.stop="payload.unrelateClickHandler(item)">
                        <v-icon>mdi-repeat-off</v-icon>
                      </v-btn>
                      <v-btn icon v-if="payload.isChecklist && item.can_send_email==1 && item.checklist_recipients !=null" @click.stop="payload.sendChecklistReport(item)" color="primary">
                        <v-icon>mdi-email</v-icon>
                      </v-btn>
                      <v-btn icon v-if="payload.isChecklist" @click.stop="payload.changeStatus(item)" color="warning">
                        <v-icon>{{ item.is_completed ? 'mdi-reload' : 'mdi-check'}}</v-icon>
                      </v-btn>
                      <v-btn icon v-if="payload.isChecklist && item.has_template && item.is_file_uploaded" @click.stop="payload.pdfTemplateReportDownload(item)" color="primary">
                        <v-icon>mdi-file-pdf</v-icon>
                      </v-btn>
                      <v-btn icon v-else @click.stop="payload.downloadHandler(item)" color="primary">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                      <v-btn icon @click.stop="deleteRecord(item)" v-if="payload.hasPermission && !payload.isChecklist">
                        <v-icon color="error">mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </template>
          <v-alert prominent text v-if="!payload.loading && payload.list.length === 0" type="info" class="mt-2" >
            <v-row align="center">
              <v-col class="grow">{{ $t('message.common.noResults') }}</v-col>
            </v-row>
          </v-alert>
        </v-card-text>
      </template>
    </v-virtual-scroll>
    <v-card-text class="pa-1 mb-2">
      <div class="text-center" v-if="payload.loading">
        <v-progress-linear :active="payload.loading" :indeterminate="payload.loading" color="deep-purple accent-4"></v-progress-linear>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ['payload', 'id'],
  data () {
    return {
      loading: false,
      totalCount: 0,
      perPage: 8,
      hasNoNext: false,
      page: 1,
      search: '',
      total: 0,
      selectedTickets: [],
      /* for notes pagination */
      currentPage: 1,
      hasNextPage: true,
      dontLoadList: false,
      searchTerm: '',
      bottom: false
    }
  },
  computed: {
    /* for tickets */
    menuList () {
      return [
        { title: this.$t('createTicket'), value: 'createTicket', icon: 'mdi-palette-swatch' },
        { title: this.$t('merge'), value: 'merge', icon: 'mdi-call-merge' },
        { title: this.$t('changeStatus'), value: 'changeStatus', icon: 'mdi-priority-low' },
        { title: this.$t('changePriority'), value: 'changePriority', icon: 'mdi-currency-sign' },
        { title: this.$t('assignTo'), value: 'assignTo', icon: 'mdi-account-circle' },
        { title: this.$t('delete'), value: 'delete', icon: 'mdi-delete' }
      ]
    }
  },
  watch: {
    search (val) {
      this.$eventBus.$emit('loadList', { page: this.page, search: this.search, itemsPerPage: this.perPage })
    },
    searchTerm (searchText) {
      this.payload.list = []
      const searchableProps = this.payload.items.map(x => x.value)
      const newList = []
      this.payload.completeList.forEach(element => {
        const values = []
        searchableProps.forEach(prop => {
          if (typeof element[prop] === 'string') values.push(element[prop].toLowerCase())
        })
        if (values.some(x => x.includes(searchText.toLowerCase()))) newList.push(element)
      })
      this.payload.list = this.$formatter.cloneVariable(searchText ? newList : this.payload.completeList)
    },
    page: {
      handler (val) {
        if (!this.dontLoadList) this.$eventBus.$emit('loadList', { page: val, search: this.search, itemsPerPage: this.perPage })
      },
      deep: true
    },
    selectedTickets () {
      this.$emit('update-selected-tickets', this.selectedTickets)
    },
    bottom (val) {
      if (val) {
        if (this.payload.list.length !== this.payload.total) this.$eventBus.$emit('loadList', this.search)
      }
    }
  },
  mounted () {
    const paginationStoredValue = this.payload.storeIndex ? this.$store.state.common[this.payload.storeIndex] : undefined
    const pagination = paginationStoredValue ? !this.$formatter.isEmptyObject(paginationStoredValue) ? this.$store.state.common[this.payload.storeIndex] : { itemsPerPage: this.perPage, page: 1 } : { itemsPerPage: this.perPage, page: 1 }
    this.page = pagination.page
    this.$eventBus.$on('getCount', getCountParams => {
      const { totalCount, page, actionDelete } = getCountParams
      if (actionDelete && this.page !== page) {
        this.dontLoadList = true
        this.page = page
      }
      if (this.page === totalCount) this.hasNoNext = true
      else this.hasNoNext = false
      setTimeout(() => {
        this.dontLoadList = false
      }, 50)
    })
    this.$eventBus.$on('resetSelectedTickets', () => {
      this.selectedTickets = []
    })
    this.$eventBus.$on('changePage', (pageNo) => {
      this.changePage(pageNo)
    })
    const el = document.getElementById(this.id)
    if (el) {
      el.addEventListener('scroll', () => {
        this.bottom = this.bottomVisible()
      })
      this.payload.listenerAdded = true
    }
  },
  methods: {
    deleteRecord (record) {
      const paginatePage = this.payload.list.length === 1 && this.page > 1 ? this.page - 1 : this.page
      this.$eventBus.$emit('deleteItems', { module: this.payload.module, id: record.id, checklistId: record.checklist_api_id, pagination: { page: paginatePage, search: this.search, itemsPerPage: this.perPage, actionDelete: true } })
    },
    previousList () {
      this.page--
      this.checkIsNextEnabled()
    },
    nextList () {
      if (this.page === this.totalCount) this.hasNoNext = true
      else {
        this.page++
        this.hasNoNext = false
      }
    },
    checkIsNextEnabled () {
      if (this.page === this.totalCount) this.hasNoNext = true
      else {
        this.hasNoNext = false
      }
    },
    toggleFilter () {
      this.$eventBus.$emit('showHideFilter')
    },
    /* for notes pagination */
    prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
        this.changePage(this.currentPage)
      }
    },
    nextPage () {
      if (this.currentPage < this.numPages()) {
        this.currentPage++
        this.changePage(this.currentPage)
      }
    },
    changePage (page) {
      this.currentPage = page
      this.payload.list = []
      if (page < 1) page = 1
      if (page > this.numPages()) page = this.numPages()
      if (this.payload.completeList.length > 0) {
        for (var i = (page - 1) * this.perPage; i < (page * this.perPage) && i < this.payload.completeList.length; i++) {
          this.payload.list.push(this.payload.completeList[i])
        }
      }
      if (page === 1) {
        this.hasNextPage = true
      }
      // eslint-disable-next-line
      if (page == this.numPages()) {
        this.hasNextPage = true
      } else {
        this.hasNextPage = false
      }
    },
    numPages () {
      return Math.ceil(this.payload.completeList.length / this.perPage)
    },
    bottomVisible () {
      const el = document.getElementById(this.id)
      const scrollY = el.scrollTop
      const visible = el.clientHeight
      const pageHeight = el.scrollHeight
      const bottomOfPage = visible + scrollY + 1 >= pageHeight
      return bottomOfPage || pageHeight < visible
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('getCount')
    this.$eventBus.$off('resetSelectedTickets')
    this.$eventBus.$off('changePage')
  }
}
</script>
<style>
.custom_list_style {
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.6) !important;
  padding: 4px !important;
  margin-top: 4px !important;
  min-height: 50px !important;
}
.custom-margin-auto {
  margin: auto !important;
}
.list_action_custom_margin {
  min-width: 35px !important;
}
.avatar_custom_margin, .list_action_custom_margin {
  margin: 0 !important;
}
.avatar_custom_margin {
  margin-right: 8px !important;
}
.custom_scroll_style {
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
