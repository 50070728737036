import { generate_pdf_content as generatePDFContent } from './pdf_template'
import { componentStatusList } from './support_list'
import { checklistApi } from '../plugins/axios_settings'
export default {
  downloadReportHelper (item, token, logoBase64, isEmailReport = false, allTransData) {
    return new Promise((resolve, reject) => {
      try {
        const checklistId = item.checklist_api_id
        const formId = item.checklist_template_api_id
        const formName = item.title
        let checklilstName = item.checklist_name
        let projectdata = item.projectdata
        let listOfFields = []
        const headers = { headers: { 'X-AccessToken': token } }
        checklistApi.get(`formproperties/get_by_form/${formId}`, headers).then((response) => {
          const list = response.data
          for (let i = 0; i < list.length; i++) {
            list[i].default_value = list[i].default_value ? JSON.parse(list[i].default_value) : null
            if (list[i].type === 18) {
              const defaultValue = list[i].default_value
              if (defaultValue.grid_data && defaultValue.grid_data.labels) list[i].showSum = this.checkAndGetSumRowData(defaultValue.grid_data.labels)
            }
            const getPanelFieldIndex = list.findIndex(x => x._id === list[i].default_value.panelId)
            if (getPanelFieldIndex >= 0) {
              list[i].isPanelField = true
              if (!list[getPanelFieldIndex].panel_fields) list[getPanelFieldIndex].panel_fields = []
              list[getPanelFieldIndex].panel_fields.push(Object.assign({ ...list[i] }, { isPanelField: false }))
            }
          }
          listOfFields = list
          checklistApi.get(`checklists/${checklistId}`, headers).then((response) => {
            let fields = response.data
            const data = fields.data ? JSON.parse(fields.data) : null
            for (const key in data) {
              if (/_filter/.test(key)) {
                var orgKey = key.replace(new RegExp('_filter$'), '')
                if (Object.keys(data[key]).length > 0) data[orgKey] = data[key]
              }
            }
            fields = data
            const model = { content: '' }
            const dataGridsWithLargeColumns = listOfFields.filter(x => x.type === 18 && x.default_value.grid_data.labels.length > 5)
            const ids = dataGridsWithLargeColumns.map(x => x._id) || []
            listOfFields = listOfFields.filter(x => !ids.includes(x._id))
            const content = this.generatingContentForPDF(formName, listOfFields, fields, logoBase64, checklilstName, projectdata, allTransData, item.report_header)
            model.content = btoa(unescape(encodeURIComponent(content)))
            if (dataGridsWithLargeColumns.length) {
              const dataGridShow = true
              const dataGridContent = this.generatingContentForPDFDataGrid(formName, dataGridsWithLargeColumns, fields, logoBase64, checklilstName = '', projectdata = [], allTransData, dataGridShow, item.report_header)
              model.datagrid_content = btoa(unescape(encodeURIComponent(dataGridContent)))
              model.islandscapedatagrid = (dataGridsWithLargeColumns[0].default_value.grid_data.paper && dataGridsWithLargeColumns[0].default_value.grid_data.paper.islandscapedatagrid) ? dataGridsWithLargeColumns[0].default_value.grid_data.paper.islandscapedatagrid : false
              model.datagridpdfpagesize = dataGridsWithLargeColumns[0].default_value.grid_data.paper ? dataGridsWithLargeColumns[0].default_value.grid_data.paper.paperSize : 1
            }
            // model.content = this.generatingContentForPDF(formName, listOfFields, fields, logoBase64, checklilstName, projectdata, allTransData)
            checklistApi.post(`checklists/generate_pdf/${formId}/${checklistId}`, model, headers).then((response) => {
              if (isEmailReport) {
                window.localStorage.setItem(`checklist_report_${checklistId}`, response.data)
              } else {
                window.open(`${checklistApi.defaults.baseURL}files/download?fileName=${response.data}`, '_blank')
              }
              resolve()
            })
          })
        })
      } catch (err) {
        reject(err)
      }
    })
  },
  generatingContentForPDF (formName, listOfFields, fields, logoBase64, checklilstName = '', projectdata = '', allTransData, reportHeader = '') {
    const result = generatePDFContent(formName, listOfFields, fields, componentStatusList, logoBase64, checklilstName, projectdata, allTransData, false, reportHeader)
    return result
  },
  generatingContentForPDFDataGrid (formName, listOfFields, fields, logoBase64, checklilstName = '', projectdata = '', allTransData, dataGridShow, reportHeader = '') {
    const result = generatePDFContent(formName, listOfFields, fields, componentStatusList, logoBase64, checklilstName, projectdata, allTransData, dataGridShow, reportHeader)
    return result
  },
  checkAndGetSumRowData (labels) {
    const obj = { showSumRow: false, sumColIndexes: [] }
    labels.forEach((label, index) => {
      const { field } = label
      if (field && field.typedata && field.typedata.isshowsum) {
        obj.showSumRow = true
        obj.sumColIndexes.push(index)
      }
    })
    return obj
  }
}
