<template>
  <div>
    <v-card flat class="pa-1" v-if="['project', 'equipment', 'chemicallist'].includes(module)">
      <v-card-text class="pa-0">
        <v-row>
          <v-col class="pa-1 pt-2" cols="12" lg="8" sm="12" md="8">
            <h3 v-if="module === 'project'">{{$t('message.projects.project')}}: <router-link v-if="propId" :to="`/projects/${$route.params[propId]}`">{{ printProjectName }}</router-link> <router-link  v-else :to="`/projects/${globalListSelectedProjectId}`">{{ printProjectName }}</router-link></h3>
            <h3 v-if="module === 'equipment'">{{printEquipmentName}}</h3>
            <h3 v-if="module === 'chemicallist'">{{printChemicallistName}}</h3>
          </v-col>
          <v-col class="pa-1" cols="12" lg="4" md="4" sm="12">
            <v-autocomplete filled v-model="moduleId" :items="moduleList" dense hide-details clearable label="ModuleName" item-text="name" item-value="id"></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row class="mx-0 mb-1">
      <v-col cols="12" lg="8" sm="12" md="8" class="pa-1 px-1">
        <!-- <v-autocomplete :disabled="checklistTemplatesLoading" :loading="checklistTemplatesLoading" :items="getSelectedChecklists" @change="goToChecklistForm" item-text="title" item-value="id" v-model="getChecklist" filled :label="$t('message.layout.checklists')" dense hide-details></v-autocomplete> -->
        <v-autocomplete chips color="blue-grey lighten-2" :disabled="checklistTemplatesLoading" :loading="checklistTemplatesLoading"
          :items="getSelectedChecklists" @change="goToChecklistForm" item-text="title" item-value="id" v-model="getChecklist"
          filled :label="$t('message.layout.checklists')" dense hide-details class="test-class"></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="12" class="pa-1 px-1">
        <v-select v-model="checklistStatus" :items="checklistStatusList" :item-text="(val) => $t(val.text)" item-value="value" filled dense hide-details :label="$t('message.projects.status')"></v-select>
      </v-col>
    </v-row>
    <compact-list :payload="compactListPayload" id="globalChecklist" v-if="compactListPayload.list.length"></compact-list>
    <template v-else>
      <v-card class="mt-2 pa-0" elevation="0">
        <v-card-text class="text-h6 text-center font-weight-bold">  {{ $t('message.common.no_records') }} </v-card-text>
      </v-card>
    </template>
    <!-- status change dialog -->
    <v-dialog v-model="statusChangeDialog" persistent max-width="400px" class="mt-0">
      <v-card>
        <v-card-title>
          {{ $t('message.common.confirmStatusChange') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          {{ $t('message.common.areYouSureToChangeStatus', { statusText: activeItemForStatusChange && activeItemForStatusChange.is_completed ? $t('message.common.incomplete') : $t('message.common.complete') }) }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" id="statusChangeConfirm" :loading="statusChangeLoading" @click="confirmChangeStatus">{{ $t('message.common.yes') }}</v-btn>
          <v-btn color="warning" id="statusChangeClose" @click="statusChangeDialog=false, activeItemForStatusChange=null">{{ $t('message.common.no') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <!-- Import CSV Dialog- test -->
    <v-dialog v-model="importCsvDialog" width="650" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">{{ $t('message.checklist.importCSV') }}
        </v-card-title>
        <v-card-text class="mt-4">
          <span v-html="$t('message.checklist.CSVFileInfo')"  class="h4 font-weight-bold"> </span>
          <ul v-for="(item, index) in csvInformations" :key="index">
            <li class="mt-1">{{ item.text }}</li>
          </ul>
          <v-divider class="my-3"></v-divider>
          <v-file-input v-model="csvFile" prepend-icon="" hide-details prepend-inner-icon="mdi-file-delimited" :label="$t('message.checklist.addCSVFile')" accept=".csv" outlined dense></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="readCSVFileAndSetData()">{{ $t('message.common.import') }}</v-btn>
          <v-btn color="primary" @click="withoutImport()">{{ $t('message.checklist.withoutImport') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" class="white--text-custom" @click="importCsvDialog = false,  csvFile=null, getChecklist=0">{{ $t('message.common.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Copy dialog -->
    <v-dialog v-model="copyDialog" persistent max-width="400px" class="mt-0">
      <v-card>
        <v-card-text class="pa-5">
          {{ $t('message.checklist.copy_confirm') }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" id="copyConfirm" :loading="copyLoading" @click="copyChecklistConfirm">{{ $t('message.common.yes') }}</v-btn>
          <v-btn color="warning" id="copyClose" @click="copyDialog=false, activeItemForcopy=null">{{ $t('message.common.no') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CompactList from '@/components/checklist/CompactList'
import helpers from '../../utils/actions'
import { checklistApi, hostAppApi } from '../../plugins/axios_settings'
import projectMixin from '@/mixins/projectMixin'
import { fieldTypeConstants } from '@/utils/support_list'
export default {
  mixins: [projectMixin],
  props: ['module', 'model', 'payload', 'moduleTitle', 'propId', 'projectId'],
  data () {
    return {
      getChecklist: 0,
      pagination: !this.$formatter.isEmptyObject(this.$store.state.common.PROPS_INDEX) ? this.$store.state.common.PROPS_INDEX : { itemsPerPage: 8, page: 1 },
      search: '',
      getSelectedChecklists: [],
      moduleName: 'checklist',
      /* pwa */
      compactListPayload: {
        hideHeader: false,
        editHandler: this.updateChecklistDialog,
        refreshHandler: this.getListHandler,
        downloadHandler: (item) => this.downloadChecklist(item, false),
        pdfTemplateReportDownload: (item) => this.pdfTemplateReportDownload(item),
        sendChecklistReport: (item) => this.sendChecklistReport(item),
        copyChecklist: this.changeChecklistCopy,
        changeStatus: this.changeChecklistStatus,
        moduleTitle: '',
        hasNoNext: false,
        loading: false,
        list: [],
        pagination: {},
        storeIndex: 'PROPS_INDEX',
        items: [{ value: 'title' }, { value: 'checklist_name' }, { value: 'feature_name' }],
        hasFilter: false,
        isCustomModule: false, // flag to show the relate unrelate options
        isChecklist: true,
        moduleIcon: 'mdi-book-plus',
        hasPermission: true,
        backTo: this.backTo,
        startPage: 0,
        length: 15,
        total: 0
      },
      deleteSuccessPagination: null,
      hostRef: this.$cookie.get('WelditPwa_host-ref'),
      checklistDeleteId: '',
      statusChangeLoading: false,
      statusChangeDialog: false,
      activeItemForStatusChange: null,
      equipmentObj: null,
      checklistStatusList: [
        { text: 'message.projects.all', value: 3 },
        { text: 'message.customModule.completed', value: 1 },
        { text: 'message.customModule.notCompleted', value: 2 }
      ],
      checklistStatus: 2,
      checklistTemplatesLoading: false,
      globalListSelectedProjectId: 0,
      importCsvDialog: false,
      csvFile: null,
      csvInformations: [
        { text: this.$t('message.checklist.removeRow') },
        { text: this.$t('message.checklist.sameOrederColumn') },
        { text: this.$t('message.checklist.semicolonDelimiter') }
      ],
      datagridFormProperties: null,
      selectedChecklistTemplate: null,
      dataGridImportValue: null,
      fieldTypeConstants: fieldTypeConstants,
      copyLoading: false,
      copyDialog: false,
      activeItemForcopy: null,
      chemicallistObj: null,
      hostUserId: this.$cookie.get(process.env.VUE_APP_HOST_USER) ? parseInt(this.$cookie.get(process.env.VUE_APP_HOST_USER)) : 0,
      moduleList: [],
      projectIdForFilterList: null,
      moduleId: null,
      moduleType: null
    }
  },
  components: {
    'compact-list': CompactList
  },
  computed: {
    ...mapGetters(['getChecklistForms', 'listOfModules', 'userDetails', 'getHostRefApi', 'userId']),
    printEquipmentName () {
      if (this.equipmentObj) return this.setEquimentName(this.equipmentObj)
      else return ''
    },
    printChemicallistName () {
      if (this.chemicallistObj) return this.setChemicallistName(this.chemicallistObj)
      else return ''
    }
  },
  created () {
    this.getListOfChecklists()
  },
  watch: {
    checklistStatus (val, oldval) {
      if (val !== oldval) {
        this.compactListPayload.startPage = 0
        this.compactListPayload.total = 0
        this.compactListPayload.list = []
        this.getListHandler(null)
      }
    },
    moduleId (oldval, newval) {
      if ((this.moduleId)) {
        if (!Number(this.moduleId)) this.moduleType = 4
        else this.moduleType = 3
      } else {
        this.moduleType = 2
      }
      this.compactListPayload.startPage = 0
      this.compactListPayload.total = 0
      this.compactListPayload.list = []
      this.getListHandler(null)
      this.getListOfChecklists()
    }
  },
  mounted () {
    this.compactListPayload.moduleTitle = `${this.moduleTitle}`
    this.$eventBus.$on('deleteItems', (data) => {
      if (data.module === this.moduleName) {
        this.deleteSuccessPagination = data.pagination
        this.checklistDeleteId = data.checklistId
        const payload = { url: `checklist/${data.id}`, isSingle: true, module: this.moduleName, fromHostApi: true }
        this.$store.commit('showDeleteDialog', payload)
      }
    })
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.moduleName) {
        this.pagination = this.deleteSuccessPagination
        this.deleteChecklistItem()
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'message.common.deleteSuccess' })
        this.$store.commit('hideDialog')
        this.getListHandler()
      }
    })
    this.$eventBus.$on('clearChecklist', (projectId) => {
      this.getChecklist = null
      this.compactListPayload.startPage = 0
      this.compactListPayload.total = 0
      this.compactListPayload.list = []
      this.getListHandler(projectId || null)
    })
    this.$eventBus.$on('loadList', (data) => {
      this.compactListPayload.startPage = this.compactListPayload.list.length
      this.getListHandler(data)
    })
    this.getModulesName()
    if (this.$route && this.$route.query && !this.$route.query.moduleName) this.getListHandler()
  },
  methods: {
    downloadChecklist (item, isEmailReport = false) {
      this.compactListPayload.loading = true
      this.$eventBus.$emit('showLoading')
      hostAppApi.get(`${this.getHostRefApi}get-organization-logo?user_id=${this.userId}`)
        .then((res) => {
          const allTransData = { attachmentsBelow: this.$t('message.mixed.attachmentsBelow') }
          helpers.downloadReportHelper(item, this.$cookie.get(process.env.VUE_APP_CHECKLIST_ACCESS_TOKEN), res.data || '', isEmailReport, allTransData)
            .then(() => {
              this.$eventBus.$emit('hideLoading')

              if (isEmailReport) {
                const checklistReport = window.localStorage.getItem(`checklist_report_${item.checklist_api_id}`)
                const url = `https://${this.hostRef}/api/checklist-send-reprot/${item.checklist_api_id}?checklist_report_file=${checklistReport}`
                hostAppApi.get(url).then((response) => {
                  const text = response.data.message

                  if (response.data.status === 'success') {
                    this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
                  } else {
                    this.$root.$emit('snackbar', { snackbar: true, color: 'warning', text })
                  }
                }).catch((error) => {
                  this.$handleServerSideError(error)
                })
              }
            })
            .finally(() => { this.compactListPayload.loading = false })
        })
    },
    pdfTemplateReportDownload (item) {
      this.compactListPayload.loading = true
      hostAppApi.get(`${this.getHostRefApi}checklist/${item.id}/report?user_id=${this.userId}`)
        .then((res) => {
          if (res && res.data.message) this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: res.data.message, isException: true })
          if (res && res.data && res.data.file) window.open(res.data.file, '_blank')
        })
        .finally(() => { this.compactListPayload.loading = false })
    },
    getListOfChecklists (projectId = null, isFromGlobalProject) {
      this.checklistTemplatesLoading = true
      if (isFromGlobalProject) this.globalListSelectedProjectId = projectId
      projectId = projectId || this.$route.params[this.propId]
      if (this.$route.path === '/global_checklist') projectId = projectId || this.globalListSelectedProjectId
      let url = `https://${this.hostRef}/api/get_checklist_templates?status=2&is_list=1`
      switch (this.module) {
        case 'project':
          var moduleId = Number(this.moduleId ? this.moduleId.toString().split('-')[0] : '')
          if (moduleId) url += `&api_project_id=${projectId}&object_id=${moduleId}&object_type=${this.moduleType}`
          else url += `&api_project_id=${projectId}&object_id`
          this.getProject(parseInt(projectId))
          break
        case 'equipment':
          url += `&object_type=6&api_object_id=${this.$route.params[this.propId]}`
          this.getEquipment(parseInt(this.$route.params[this.propId]))
          break
        case 'chemicallist':
          url += `&object_type=5&api_object_id=${this.$route.params[this.propId]}`
          this.getChemicallist(parseInt(this.$route.params[this.propId]))
          break
        default:
          break
      }
      hostAppApi.get(url).then((response) => {
        if (response && response.data.data) {
          const finalList = []
          for (const [categoryName, list] of Object.entries(response.data.data)) {
            finalList.push({ header: categoryName })
            list.forEach(el => {
              finalList.push(el)
            })
            finalList.push({ divider: true })
          }
          this.getSelectedChecklists = finalList || []
        }
        this.checklistTemplatesLoading = false
      })
    },
    getListHandler (projectId = null, isFromGlobalProject) {
      if (isFromGlobalProject) {
        this.compactListPayload.startPage = 0
        this.compactListPayload.total = 0
        this.compactListPayload.list = []
        this.globalListSelectedProjectId = projectId
      }
      projectId = projectId || this.$route.params[this.propId]
      if (this.$route.path === '/global_checklist') projectId = projectId || this.globalListSelectedProjectId
      let url = `https://${this.hostRef}/api/checklist?object_type=`
      switch (this.module) {
        case 'global':
          url += '1'
          break
        case 'project':
          var moduleId = Number(this.moduleId ? this.moduleId.toString().split('-')[0] : '')
          if (moduleId) url += `${this.moduleType}&api_project_id=${projectId}&api_object_id=${moduleId}`
          else url += `2&api_project_id=${projectId}`
          break
        case 'equipment':
          url += `6&api_object_id=${this.$route.params[this.propId]}`
          break
        case 'chemicallist':
          url += `5&api_object_id=${this.$route.params[this.propId]}`
          break
        default:
          break
      }
      url += `&status=${this.checklistStatus || 3}`
      url += `&length=${this.compactListPayload.length}`
      url += `&start=${this.compactListPayload.startPage}`
      this.compactListPayload.loading = true
      this.compactListPayload.module = this.moduleName
      hostAppApi.get(url).then((response) => {
        if (response && response.data) {
          this.compactListPayload.list = [...this.compactListPayload.list, ...response.data.data]
          this.compactListPayload.total = response.data.total
        }
      }).finally(() => {
        this.compactListPayload.loading = false
      })
    },
    goToChecklistForm () {
      this.checklistTemplateChange()
      // const getForm = this.getSelectedChecklists.find(x => x.id === this.getChecklist)
      // if (getForm) {
      //   const projectChecklistFormData = getForm.formdata && getForm.formdata.data ? getForm.formdata.data : null
      //   this.$eventBus.$emit('checklistDialog', { form_id: getForm.checklist_template_api_id, form_name: getForm.title, itemId: getForm.id, projectChecklistFormData, can_send_email: getForm.can_send_email, projectId: this.projectId, getChecklist: this.getChecklist })
      // }
      return ''
    },
    updateChecklistDialog (item) {
      this.$eventBus.$emit('updatechecklistDialog', { checklist_id: item.checklist_api_id, itemId: item.checklist_template_id, form_id: item.checklist_template_api_id, form_name: item.title, title: item.checklist_name || '', checklist_recipients: item.checklist_recipients || '', can_send_email: item.can_send_email, checklist_data: item })
    },
    deleteChecklistItem () {
      checklistApi.delete(`/checklists/${this.checklistDeleteId}`)
        .then((response) => {
        }).finally(() => {
          this.copyLoading = false
          this.copyDialog = false
          this.activeItemForCopy = null
          this.compactListPayload.startPage = 0
          this.compactListPayload.total = 0
          this.compactListPayload.list = []
          this.getListHandler()
        })
    },
    changeChecklistStatus (item) {
      this.activeItemForStatusChange = item
      this.statusChangeDialog = true
    },
    confirmChangeStatus () {
      this.statusChangeLoading = true
      const isCompleted = this.activeItemForStatusChange.is_completed === 0 ? 1 : 0
      const model = { checklist_template_id: this.activeItemForStatusChange.checklist_template_id, is_completed: isCompleted }
      hostAppApi.put(`https://${this.hostRef}/api/checklist/${this.activeItemForStatusChange.checklist_api_id}`, model)
        .then((response) => {
        }).finally(() => {
          this.statusChangeLoading = false
          this.statusChangeDialog = false
          this.activeItemForStatusChange = null
          this.copyLoading = false
          this.copyDialog = false
          this.activeItemForCopy = null
          this.compactListPayload.startPage = 0
          this.compactListPayload.total = 0
          this.compactListPayload.list = []
          this.getListHandler()
        })
    },
    backTo () {
      let url = ''
      switch (this.module) {
        case 'global':
          url = '/dashboard'
          break
        case 'project':
          url = `/projects/${this.$route.params[this.propId]}`
          break
        case 'equipment':
          url = `/equipments/${this.$route.params[this.propId]}`
          break
        case 'chemicallist':
          url = `/chemicallist/${this.$route.params[this.propId]}`
          break
        default:
          break
      }
      this.$router.push(url)
    },
    getEquipment (id) { // equipment id
      hostAppApi.get(`https://${this.hostRef}/api/equipment/${id}/edit`).then((response) => {
        if (response && response.data) this.equipmentObj = this.$formatter.cloneVariable(response.data)
      })
    },
    getChemicallist (id) { // chemicallist id
      hostAppApi.get(`https://${this.hostRef}/api/chemicallist/${id}/edit`).then((response) => {
        if (response && response.data) this.chemicallistObj = this.$formatter.cloneVariable(response.data)
      })
    },
    sendChecklistReport (item) {
      window.localStorage.setItem(`checklist_report_${item.checklist_api_id}`, false)
      this.downloadChecklist(item, true)
    },
    checklistTemplateChange () {
      const cprojectId = ''
      hostAppApi.get(`https://${this.hostRef}/api/checklist-template-builder/${this.getChecklist}/edit?project_id=${cprojectId}`)
        .then((response) => {
          if (response.data.status !== 'error' && response.data.status !== 'danger') {
            this.selectedChecklistTemplate = response.data
            if (+this.selectedChecklistTemplate.checklist_type_id === 2) this.importCsvDialog = true
            this.checklistProjectData = response.data.formdata ? response.data.formdata.data : []
            const getForm = this.getSelectedChecklists.find(x => x.id === this.getChecklist)
            if (getForm && !this.importCsvDialog) {
              const projectChecklistFormData = getForm.formdata && getForm.formdata.data ? getForm.formdata.data : null
              var moduleId = Number(this.moduleId ? this.moduleId.toString().split('-')[0] : '')
              this.$eventBus.$emit('checklistDialog', { form_id: getForm.checklist_template_api_id, form_name: getForm.title, itemId: getForm.id, projectChecklistFormData, can_send_email: getForm.can_send_email, projectId: this.projectId, getChecklist: this.getChecklist, getForm: getForm, moduleId: moduleId, moduleType: this.moduleType })
            }
          }
        })
        .catch((error) => {
          this.$handleServerSideError(error)
        })
    },
    readCSVFileAndSetData () {
      // var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.csv|.txt)$/
      // if (regex.test(this.csvFile.name.toLowerCase())) {
      if (this.csvFile.name.split('.').pop().toLowerCase() !== 'csv') {
        alert('Only formats are allowed : csv')
      } else {
        if (typeof (FileReader) !== 'undefined') {
          this.importCsvDialog = false
          /* get the form properties of checklist */
          this.getDataGridFormProperties()
          this.saveWithoutImport = false
        } else alert('This browser does not support HTML5.')
      }
    },
    getDataGridFormProperties () {
      if (this.selectedChecklistTemplate) {
        checklistApi
          .get(`formproperties/get_by_form/${this.selectedChecklistTemplate.checklist_template_api_id}`)
          .then((response) => {
            if (response && response.data) this.datagridFormProperties = response.data.filter(x => x.type === 18) // get only datagrids
            const model = {
              title: this.ckTitle,
              data: {},
              form_id: this.selectedChecklistTemplate.checklist_template_api_id
            }
            model.data.values = []
            /* If DataGrid & type Import & has csvfile */
            var getDataFromCSV = new Promise((resolve, reject) => this.setDataFromCSV(resolve, reject))
            getDataFromCSV.then((addedRows) => {
              this.csvFile = null
              model.data = { ...model.data, ...addedRows }
              this.dataGridImportValue = model
              const getForm = this.getSelectedChecklists.find(x => x.id === this.getChecklist)
              if (getForm) {
                const projectChecklistFormData = getForm.formdata && getForm.formdata.data ? getForm.formdata.data : null
                var moduleId = Number(this.moduleId ? this.moduleId.toString().split('-')[0] : '')
                this.$eventBus.$emit('checklistDialog', { form_id: getForm.checklist_template_api_id, form_name: getForm.title, itemId: getForm.id, projectChecklistFormData, can_send_email: getForm.can_send_email, projectId: this.projectId, getChecklist: this.getChecklist, dataGridImportValue: this.dataGridImportValue, getForm: getForm, moduleId: moduleId, moduleType: this.moduleType })
              }
            }).catch((e) => {})
          })
      }
    },
    setDataFromCSV (resolve, reject) {
      const datagridFormProperties = this.datagridFormProperties || []
      const data = {}
      var self = this
      try {
        var reader = new FileReader()
        reader.onload = function (e) {
          var rows = e.target.result.split('\n')
          datagridFormProperties.forEach(gd => { // gd-grid
            data[gd.name] = {}
            const defaultValue = gd.default_value ? JSON.parse(gd.default_value) : null
            const properties = defaultValue.grid_data.labels
            let resultGrid = {}
            rows.forEach((row, index) => {
              if (!row) return
              row = row.replace('\r', '')
              var separator = ';'
              if (row.includes(';')) separator = ';'
              const gridData = []
              var cells = row.split(separator)
              for (let i = 0; i < +gd.label; i++) {
                let value = cells[i] || ''
                if (properties[i].field.type === self.fieldTypeConstants.CHECKBOX) value = value ? [value] : []
                gridData.push({ value, isShow: true })
              }
              resultGrid = Object.assign({ ...resultGrid }, { [`row_${index}`]: [...gridData] })
            })
            data[gd.name] = resultGrid
          })
          resolve(data)
        }
        reader.readAsText(this.csvFile, 'ISO-8859-1')
      } catch (ex) { reject(ex) }
    },
    withoutImport () {
      this.importCsvDialog = false
      this.csvFile = null
      const getForm = this.getSelectedChecklists.find(x => x.id === this.getChecklist)
      if (getForm) {
        const projectChecklistFormData = getForm.formdata && getForm.formdata.data ? getForm.formdata.data : null
        var moduleId = Number(this.moduleId ? this.moduleId.toString().split('-')[0] : '')
        this.$eventBus.$emit('checklistDialog', { form_id: getForm.checklist_template_api_id, form_name: getForm.title, itemId: getForm.id, projectChecklistFormData, can_send_email: getForm.can_send_email, projectId: this.projectId, getChecklist: this.getChecklist, getForm: getForm, moduleId: moduleId, moduleType: this.moduleType })
      }
    },
    changeChecklistCopy (item) {
      this.activeItemForCopy = item
      this.copyDialog = true
    },
    copyChecklistConfirm (item) {
      this.copyLoading = true
      const model = { id: this.activeItemForCopy.id, user_id: this.userId }
      hostAppApi.post(`https://${this.hostRef}/api/checklist-new/${this.activeItemForCopy.id}/copy`, model)
        .then((response) => {
          // this.getListHandler()
          const message = response.data.message
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: message })
        }).finally(() => {
          this.copyLoading = false
          this.copyDialog = false
          this.activeItemForCopy = null
          this.compactListPayload.startPage = 0
          this.compactListPayload.total = 0
          this.compactListPayload.list = []
          this.getListHandler()
        })
    },
    getModulesName (projectId) {
      var projectID = !projectId ? this.$route.params.project_id : projectId
      if (projectID) {
        hostAppApi.get(`https://${this.hostRef}/api/get_project_modules_list?project_id=${projectID}&user_id=${this.hostUserId}`, { headers: { language: this.$i18n.locale } })
          .then((response) => {
            this.moduleList = response.data.filter(x => x && x.name && x.id)
            this.moduleList.forEach((x, index) => {
              if (x && !x.field) this.moduleList[index].id = `${x.id}-C`
            })
            if (this.$route && this.$route.query && this.$route.query.moduleName) {
              var listA = this.moduleList.find(x => x.tab === this.$route.query.moduleName)
              var listB = this.moduleList.find(x => x.name === this.$route.query.moduleName)
              if (listA && listA.object_type === 3) this.moduleId = listA.id
              else if (listB && listB.object_type === 4) this.moduleId = listB.id
              else if (this.$route && this.$route.query && this.$route.query.moduleName === 'project_deviation') this.moduleId = 1
              else this.moduleId = 0
            }
          })
      }
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
    this.$eventBus.$off('deleteItems')
    this.$eventBus.$off('clearChecklist')
    this.$eventBus.$off('loadList')
  }
}
</script>
